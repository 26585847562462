<template>
  <div
    ref="provider"
    class="TextInput"
    :class="{
      'mt-6': label,
    }"
    tag="div"
    :name="name"
  >
    <input
      v-if="type !== 'textarea'"
      :id="id"
      v-bind="$attrs"
      v-model="value"
      :value="value"
      :name="name"
      class="w-full h-full py-3 px-5 leading-normal bg-inherit min-h-[46px]"
      :class="{
        'placeholder-primary-1-100': filled && dark && !isError,
        'bg-gray-850': filled && !dark && !isError,
        'bg-system-red-light': filled && isError,
        'placeholder-black': !filled && !isError,
        'border border-system-warning': fullBorder && border && isError,
        'border border-primary-1-80': border && !isError,
        'border-r border-primary-1-15': borderRight && !isError,
        'rounded-xl': rounded,
        'has-value': hasValue,
        'border-b': underline,
        'font-bold': !light,
        'pl-11': $slots.icon,
        'px-4': !$slots.icon,
        'border border-primary-1-20': fullBorder,
      }"
      :type="showPassword ? 'text' : type"
      :placeholder="placeholder"
      :autocomplete="autocomplete"
      :aria-invalid="Boolean(isError) || undefined"
      :aria-describedby="Boolean(isError) ? `${id}-error` : undefined"
      :required="required || undefined"
      @focus="emit('focus')"
      @blur="
        () => {
          checkShowError();
          emit('blur');
        }
      "
    />

    <textarea
      v-if="type === 'textarea'"
      :id="id"
      v-model="value"
      :name="name"
      :type="showPassword ? 'text' : type"
      :placeholder="placeholder"
      v-bind="$attrs"
      class="w-full py-4 leading-normal bg-inherit"
      :class="{
        'bg-primary-1-100 placeholder-primary-1-60': filled && dark && !light && !isError,
        'bg-gray-850': filled && !dark && !isError,
        'bg-system-red-light': filled && isError,
        'border border-system-red': border && isError,
        'border border-primary-1-80': border && !isError,
        'rounded-xl': rounded,
        'has-value': hasValue,
        'pl-10': icon,
        'px-3': !icon,
        'border-b': underline,
        'font-bold': !light,
        'pl-11': isError,
        'border border-primary-1-20': fullBorder,
        'border border-system-warning': fullBorder && isError,
      }"
      :aria-invalid="Boolean(isError) || undefined"
      :aria-describedby="Boolean(isError) ? `${id}-error` : undefined"
      @focus="emit('focus')"
      @blur="
        () => {
          checkShowError();
          emit('blur');
        }
      "
    ></textarea>

    <button
      v-if="type === 'password'"
      type="button"
      class="w-6 h-6 absolute right-5 z-10"
      @click="showPassword = !showPassword"
    >
      <svg-icon :name="showPassword ? 'eye' : 'eye-hide'" :width="28" :height="28" />
    </button>

    <svg-icon-error
      v-if="isError"
      width="21"
      height="21"
      class="w-4 h-4 absolute right-4 z-20 text-danger"
      :class="[type === 'password' ? 'right-14' : 'right-3']"
    />

    <label class="TextInput__label" :for="id">
      <slot name="label" />
      <template v-if="!$slots.label">
        <span>{{ label }}</span>
        <span>{{ required ? ' *' : '' }}</span>
      </template>
    </label>

    <span v-if="isError" :id="`${id}-error`" class="TextInput__error">{{ errorMessage || '' }}</span>
    <div v-else-if="$slots.hint" class="TextInput__hint">
      <slot name="hint" />
    </div>

    <slot v-if="$slots.icon" name="icon" />

    <slot name="after" />
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  id: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: '',
  },
  type: {
    type: String as PropType<'text' | 'password' | 'textarea' | 'tel' | 'email' | 'number'>,
    default: 'text',
    validator: (value: string) => ['text', 'password', 'textarea', 'tel', 'email', 'number'].includes(value),
  },
  rules: {
    type: [String, Object],
    default: '',
  },
  dark: {
    type: Boolean,
    default: false,
  },
  autocomplete: {
    type: String,
    default: '',
  },
  filled: {
    type: Boolean,
    default: false,
  },
  rounded: {
    type: Boolean,
    default: false,
  },
  icon: {
    type: String,
    default: '',
  },
  border: {
    type: Boolean,
    default: false,
  },
  fullBorder: { type: Boolean, default: false },
  underline: { type: Boolean, default: false },
  light: { type: Boolean, default: false },
  required: { type: Boolean, default: false },
  transparent: { type: Boolean, default: true },
  borderRight: { type: Boolean, default: false },
  isOptional: { type: Boolean, default: false },
});
const showPassword = ref(false);

const { value, errorMessage, meta } = useField<string>(() => props.name);
const hasValue = computed(() => {
  return Boolean(value);
});
const emit = defineEmits(['focus', 'blur', 'input']);

const isError = computed(() => !!errorMessage.value?.length && meta.touched);

/**
 * will be called when user leaves the input field.
 * if the user didn't input any data, then set touched flag
 * to avoid showing error
 */
function checkShowError() {
  if (!meta.dirty) {
    meta.touched = false;
    return;
  }

  if (!meta.valid) {
    meta.touched = true;
  }
}

watch(
  () => meta.valid,
  valid => {
    // To re-evaluate when validity changes to false
    // without having to use the input event to avoid aggressive validation
    if (!valid) {
      checkShowError();
      emit('blur');
    }
  },
);
</script>

<style lang="postcss" scoped>
.TextInput {
  @apply relative flex items-center appearance-none w-full;
  input,
  textarea {
    @apply z-10 text-sm font-bold rounded-xl;
    position: relative;

    &::placeholder {
      @apply font-normal;
    }

    /* &.with-border {
      @apply border border-primary-1-20 rounded-xl;
    } */
  }

  &__label {
    @apply absolute text-sm block -top-6 bottom-0 inset-x-0 w-full leading-normal text-primary-1-60;
    user-select: none;
  }

  &__error,
  &__hint {
    @apply -mt-2 block text-system-red text-xs absolute left-3 w-full;
    bottom: -20px;
  }

  input:focus,
  textarea:focus {
    @apply outline-primary-1-20;
  }
}

input::before {
  content: 'looks good';
  color: green;
}

:deep(.nuxt-icon svg) {
  height: 24px;
  width: 24px;
}
</style>

<i18n>
{
  "en": {
    "optional": " (Optional)"
  },
  "ar": {
    "optional": " (اختياري)"
  }
}
</i18n>
